.MuiList-root .active {
    background-color: #e0e0e0;
}

.MuiList-root .active:hover {
    background-color: #f5f5f5;
}

:root {
    --status-pending-color: #c7ffcf;
    --status-completed-color: #f9d3d3;
    --status-in-progress-color: #add8e6;
}

.status-pending {
    background-color: var(--status-pending-color) !important;
}

.status-completed {
    background-color: var(--status-completed-color) !important;
}

.status-in-progress {
    background-color: var(--status-in-progress-color) !important;
}